@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '_scss/_variables.scss';
$tileColor: $primary-color;
$tileBg: $primary-color;
$tileHeaderColor: $color-teal;

.eventListingFilterBar {
  margin: 1rem 0;
  .filters {
    display: block;
    > * {
      margin-bottom: 0.5rem;
    }
  }

  @include media-breakpoint-up(sm) {
    .filters {
      display: flex;
      gap: 1rem;
    }
  }
}
.eventListing {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(1, 1fr);

  @include media-breakpoint-up(md) {
    /*>=768*/
    grid-template-columns: repeat(2, 1fr);
  }
  @include media-breakpoint-up(lg) {
    /*>=992*/
    grid-template-columns: repeat(3, 1fr);
  }
}
.eventListingItem {
  position: relative;
  border-radius: 0.5rem;
  box-shadow: 0 15px 15px rgba(0, 0, 0, 0.1);

  .itemLink {
    display: flex;
    flex-direction: column;
    height: 100%;

    &:hover,
    &:active {
      background-color: rgba($primary-color, 0.05);
      text-decoration: none;
      img {
        transform: scale(1.05);
      }
    }
  }

  .img {
    height: 0;
    padding-bottom: 60%;
    position: relative;
    overflow: hidden;

    border-radius: 0.5rem 0.5rem 0 0;
    > img {
      transition: all 0.5s;
      transform: scale(1);
      object-fit: cover;
      object-position: center;
      position: absolute;
      height: 100%;
      width: 100%;
    }
  }

  .details {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 10px 15px;
    line-height: 1.2;
    font-size: 0.9rem;
    .icon {
      width: 1.3em;
      height: 1.3em;
      margin-right: 0.5rem;
    }
    .title {
      font-size: 1rem;
      color: $tileHeaderColor;
      margin-bottom: 0.3rem;
      font-weight: 700;
    }
    .location,
    .date {
      margin-bottom: 0.4rem;
      color: $font-color;
      font-weight: 500;
    }

    .desc {
      flex-grow: 1;
    }
    .more {
      margin: 0.5rem 0;
      .btn {
        width: 100%;
        color: $tileHeaderColor;
        border-color: $tileHeaderColor;
      }
    }
  }

  .openHome {
    position: absolute;
    z-index: 1;
    background: #283537;
    font-weight: bold;
    bottom: 0;
    width: 100%;
    text-align: center;
  }
}

.filters {
  display: flex;
  justify-content: flex-end;
}
.eventListing--wide {
  display: block;
}
.eventListing--slider {
  .slick-prev:before,
  .slick-next:before {
    color: $primary-color;
    display: none;
  }
}

.eventListingItem--wide {
  border-radius: 0.5rem;
  margin-bottom: 1.5rem;
  .img {
    border-radius: 1rem 0 0 1rem;
  }
  .details {
    .desc {
      display: none;
      color: #283537;
      line-height: 1.3;
      margin: 1rem 0;

      text-overflow: ellipsis;
      overflow: hidden;
      // Addition lines for 2 line or multiline ellipsis
      display: -webkit-box !important;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      white-space: normal;
    }
    .more {
      margin: 0.5rem 0;
      .btn {
        width: auto;
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  .eventListing--wide {
    .slick-prev:before,
    .slick-next:before {
      display: block;
    }

    .slick-prev {
      left: -40px;
    }
    .slick-next {
      right: -40px;
    }
  }

  .eventListingItem--wide {
    background-color: white;

    > .itemLink {
      flex-direction: row;
    }
    &:after {
      display: none;
    }
    .img {
      flex: 0 0 40%;
      height: auto;
      padding-bottom: 0;
    }

    .details {
      min-height: 18rem;
      padding-left: 1.5rem;

      .title {
        font-size: 1.5rem;
        margin: 1rem 0;
      }
      .headline {
        display: block;
      }
      .desc {
        display: block;
      }
    }
  }
}
