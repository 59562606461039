.tableList {
  width: 100%;
  margin: 1.5rem 0 0.5rem;

  th {
    background-color: #ddd;
    border-bottom: solid 1px #999;
    padding: 0.4rem;
  }

  td {
    padding: 0.2rem 0.4rem;
  }

  tbody tr {
    &:nth-child(even) {
      background-color: #f0f0f0;
    }

    &:hover {
      background-color: #ddd;
    }
    &:last-child {
      border-bottom: solid 1px #999;
    }
  }
}
.pageInfo {
  margin: 5px;
}
.pagination {
  .form-control {
    display: inline-block;
    width: auto;
  }
}

.filter {
  .form-control {
    width: auto;
  }
}