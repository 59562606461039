// overriding bootstrap variables in _boostrap-override.scss

$family-base: 'proxima-nova', sans-serif;
$family-header: 'proxima-nova', serif;

// font-size
$font-size-desktop: 18px;
$font-size-mobile: 16px;

$weight-light: 300;
$weight-normal: 300;
$weight-bold: 700;

// colors - sister colours from bootrap
$color-blue: #007bff;
$color-red: #c20f2f;
$color-orange: #fd7e14;
$color-green: #28a745;

// more colors
$color-gold: #faa222;
$color-dark-gray: #3b3b3b;
$color-dark-blue: #222d64;
$color-mid-blue: #5c6594;
$color-blue-grey: #2e6072;
$color-teal: #00a69b;

// site colors
$primary-color: $color-blue-grey;
$font-color: $color-dark-gray;
$font-color-white: #fff;
$header-color: $primary-color;
$bg-color: #ebebeb;
$link-color: $primary-color;
$link-hover-color: $color-teal;

// top-bar
//$top-bar-border: (mobile: 5px, desktop: 12px);
//$top-bar-height: (mobile: 1.6rem, desktop: 2rem);
$top-bar-border: (
  mobile: 5px,
  desktop: 12px,
);
//$top-bar-height: (mobile: 2.7rem, small-device:2.8rem, tablet:3.5rem, desktop: 3.1rem);
$top-bar-height: (
  mobile: 44px,
  small-device: 56px,
  tablet: 56px,
  desktop: 56px,
);

// base transition
$transition-base: all 0.3s ease-in-out;
$transition-fast: all 0.2s ease-in-out;
$transition-slow: all 0.5s ease-in-out;
