@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '_scss/_variables.scss';

.eventView {
  margin: 1rem 0;
  display: flex;
  &__left {
    flex: 0 0 30%;
    max-width: 280px;
    padding-top: 1rem;
  }

  &__right {
    flex: 1 1 auto;
    padding-left: 1.5rem;
  }

  .btn-primary {
    background-color: $color-teal;
    border-color: $color-teal;
  }

  .btn-outline-primary {
    color: $color-teal;
    border-color: $color-teal;
  }

  .btnRegister {
    font-size: 110%;
  }

  .eventTitle {
    margin-top: 0.2rem;
    margin-bottom: 0.3rem;
  }

  .returnUrl {
    margin-bottom: 1rem;
  }

  .summary {
    margin: 0.75rem 0;
  }
  .summaryLine {
    display: flex;
    align-items: center;
    padding: 0.6rem 0;
    border-bottom: solid 2px #e0e0e0;
    img {
      width: 1.1rem;
    }
    &__text {
      margin-left: 0.5rem;
    }

    &:last-of-type {
      border-bottom: none;
    }
  }
}
